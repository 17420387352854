import {Button, Form, Input, Typography} from 'antd';
import React, {FC, useContext} from 'react';
import Pitch from '../Home/Pitch';
import {SessionContext} from '../Session';

const {Title} = Typography;

const layout = {
  labelCol: {span: 8},
  wrapperCol: {span: 16},
};
const tailLayout = {
  wrapperCol: {offset: 8, span: 16},
};
interface DocumentRequestProps {
  humanNamePlural: string;
  feminine: boolean;
}
interface DocumentRequestForm {
  code: string;
  contact: string;
  ids: string;
}

const DocumentRequest: FC<DocumentRequestProps> = ({
  humanNamePlural,
  feminine,
}) => {
  const [session] = useContext(SessionContext);
  console.log(session);

  const onFinish = ({code, contact, ids}: DocumentRequestForm) => {
    const subject = `Demande de ${humanNamePlural} - ${code}`;
    const body = `

======== Merci de ne pas modifier le contenu après cette ligne ========

Demande de ${humanNamePlural} auto-générée :

  Code : ${code}
  Contact : ${contact}
  Dates / numéros : ${ids}`;

    const uri = [
      'mailto:murielbouchet@interson-protac.com',
      `?body=${encodeURIComponent(body)}`,
      `&subject=${encodeURIComponent(subject)}`,
    ].join('');

    {
      const $anchor = document.createElement('a');
      $anchor.href = uri;
      $anchor.target = '_blank';
      document.body.appendChild($anchor);
      $anchor.click();
      $anchor.remove();
    }
  };

  return (
    <>
      <Pitch
        background="Mon espace"
        foreground={`Demande de ${humanNamePlural}`}
      />
      <p style={{marginBottom: '0'}}>
        Remplissez le formulaire ci-dessous et envoyez l'email généré afin de
        recevoir par email la copie des {humanNamePlural.toLocaleLowerCase()}{' '}
        demandé
        {feminine ? 'e' : ''}s
      </p>
      <p style={{color: '#CD1619'}}>
        Vérifiez que les informations pré-renseignées dans "Code client" et
        "Contact" correspondent à vos informations de contact.
      </p>

      <Form
        {...layout}
        name="basic"
        className="request-form"
        initialValues={{remember: true}}
        onFinish={onFinish}
        style={{marginTop: '50px'}}
      >
        <Form.Item
          label="Code client"
          name="code"
          initialValue={session?.user.code}
          rules={[
            {required: true, message: 'Veuillez entrer votre code client'},
          ]}
        >
          <Input className="input-transparent" />
        </Form.Item>
        <Form.Item
          label="Contact"
          name="contact"
          initialValue={session?.user.raisonSociale}
          rules={[{required: true, message: 'Veuillez entrer votre contact'}]}
        >
          <Input className="input-transparent" />
        </Form.Item>
        <Form.Item
          label={`${humanNamePlural} attendu${feminine ? 'e' : ''}s`}
          name="ids"
          rules={[
            {
              required: true,
              message: 'Veuillez entrer vos date(s) et/ou numéro',
            },
          ]}
        >
          <Input
            className="input-transparent"
            placeholder="Date(s) et/ou numéro"
            autoFocus={true}
          />
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button
            type="primary"
            htmlType="submit"
            style={{textTransform: 'uppercase'}}
          >
            Recevoir
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default DocumentRequest;
