import {LeftOutlined} from '@ant-design/icons';
import {Button, Card, Checkbox, Col, Row, Typography} from 'antd';
import React, {FC, useState} from 'react';
import {useLocalStorage} from '../hooks/useLocalStorage';
import FileTable from './FileTable';
import Banner from './Home/Banner';
import Pitch from './Home/Pitch';

const {Title} = Typography;

enum DocumentType {
  BROCHURE = 'BROCHURE',
  FICHES_TECHNIQUES = 'FICHES_TECHNIQUES',
  TUTORIELS_VIDEO = 'TUTORIELS_VIDEO',
}

const documentTypes: DocumentType[] = [
  DocumentType.BROCHURE,
  DocumentType.FICHES_TECHNIQUES,
  DocumentType.TUTORIELS_VIDEO,
];

const documentTypeLabels: {[type in DocumentType]: string} = {
  [DocumentType.BROCHURE]: 'Brochures',
  [DocumentType.FICHES_TECHNIQUES]: 'Fiches techniques',
  [DocumentType.TUTORIELS_VIDEO]: 'Tutoriels vidéos',
};

const documentTypeFolder: {[type in DocumentType]: string} = {
  [DocumentType.BROCHURE]: 'brochures',
  [DocumentType.FICHES_TECHNIQUES]: 'fiches_techniques',
  [DocumentType.TUTORIELS_VIDEO]: 'videos',
};

const documentTypeBackground: {[type in DocumentType]: string} = {
  [DocumentType.BROCHURE]: '/assets/2022/documentation-brochures.jpg',
  [DocumentType.FICHES_TECHNIQUES]:
    '/assets/2022/documentation-docs-techniques.jpg',
  [DocumentType.TUTORIELS_VIDEO]: '/assets/2022/documentation-tutos.jpg',
};

const TechnicalDocuments: FC = () => {
  const [agreed, setAgreed] = useLocalStorage('agreedTermsTech');
  const [documentType, setDocumentType] = useState<DocumentType>();

  if (!agreed) {
    return (
      <div style={{textAlign: 'left'}}>
        <Title>
          Téléchargement de <span style={{color: '#CD1619'}}>visuels</span>{' '}
          &amp; <span style={{color: '#CD1619'}}>brochures</span>
        </Title>
        <div
          style={{
            padding: '24px',
            border: '1px solid #ccc',
            backgroundColor: '#e6e6e6',
          }}
        >
          <Title level={2}>
            Condition d'utilisation pour la diffusion et la reproduction des
            éléments téléchargeables.
          </Title>
          <p>
            En cochant la case ci-dessous, je m'engage explicitement à respecter
            les conditions suivantes :
          </p>
          <ul>
            <li>
              Ne pas transmettre ces visuels à un tiers en aucun cas sans
              l'autorisation écrite de la société « INTERSON PROTAC » A
              mentionner en clair et en toutes lettres sur les documents, les
              marques déposées des articles représentés sur les visuels ainsi
              que la mention crédit photo : INTERSON PROTAC.
            </li>
            <li>
              A soumettre tout document à la société INTERSON PROTAC avant
              impression pour validation.
            </li>
          </ul>
          <small>
            La société INTERSON PROTAC décline toute responsabilité en cas de
            non-respect de ces règles et de diffusion non contrôlée de ses
            supports marketing.
          </small>
          <p style={{marginTop: '15px'}}>
            <Checkbox onChange={() => setAgreed('1')} checked={agreed === '1'}>
              J'accepte explicitement les conditions d'utilisation
            </Checkbox>
          </p>
        </div>
      </div>
    );
  }

  if (!documentType) {
    return (
      <div
        style={{minWidth: '900px', marginBottom: '30px'}}
        className="technical-documents"
      >
        <Pitch
          background="Documentation"
          foreground="Fiches techniques, brochures et tutoriels vidéos"
        />

        <Row>
          {documentTypes.map((documentType, index) => (
            <Col span={24} lg={8} key={index}>
              <Card
                className="document-type-card"
                style={{
                  border: 'none',
                  minHeight: '100%',
                  color: 'white',
                  cursor: 'pointer',
                }}
                onClick={() => setDocumentType(documentType)}
              >
                <div className="background">
                  <img
                    alt={`${documentTypeLabels[documentType]} logo`}
                    src={documentTypeBackground[documentType]}
                  />
                </div>
                <div className="foreground">
                  <Title>{documentTypeLabels[documentType]}</Title>
                </div>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    );
  }

  return (
    <div
      style={{minWidth: '900px', marginBottom: '30px'}}
      className="technical-documents"
    >
      <Pitch
        background="Documentation"
        foreground={documentTypeLabels[documentType]}
      />

      <Row>
        <Col span={24} style={{textAlign: 'left', marginBottom: '30px'}}>
          <Button
            type="link"
            size="large"
            onClick={() => setDocumentType(undefined)}
            style={{textTransform: 'uppercase', fontWeight: 'bold'}}
          >
            <LeftOutlined /> Retour à l'ensemble de la documentation
          </Button>
        </Col>

        <Col span={24}>
          <FileTable folder={documentTypeFolder[documentType]} />
        </Col>
      </Row>
    </div>
  );
};

export default TechnicalDocuments;
