import {Col, Row} from 'antd';
import Link from 'antd/lib/typography/Link';
import Title from 'antd/lib/typography/Title';
import React, {FC, useContext, useState} from 'react';
import {UserList} from '../api';
import {openLoginUrl, StoreType} from '../util';
import Pitch from './Home/Pitch';
import {SessionContext} from './Session';

export const storesData: {
  humanName: string;
  imageKey: string;
  type: StoreType;
  path: string;
  backgroundColor: string;
}[] = [
  {
    humanName: 'Nîmes',
    imageKey: 'nimes',
    type: 'intersonProtac',
    path: 'nimes-2025-01-30',
    backgroundColor: '#a680bf',
  },
  // {
  //   humanName: 'Paris',
  //   imageKey: 'paris',
  //   type: 'surdifuse',
  //   path: 'surdifuse',
  //   backgroundColor: '#9dda8f',
  // },
  {
    humanName: 'Lyon',
    imageKey: 'lyon',
    type: 'emboutFrancais',
    path: 'ef',
    backgroundColor: '#66a7ff',
  },
];

const Stores: FC = () => {
  const [session] = useContext(SessionContext);
  const [loading, setLoading] = useState<{[type in StoreType]?: boolean}>({});

  const handleStoreClick = async (type: StoreType) => {
    if (!session) {
      return;
    }

    setLoading({...loading, [type]: true});
    await openLoginUrl(type, session);
    setLoading({...loading, [type]: false});
  };

  return (
    <>
      <Pitch background="Embouts" foreground="" />
      <Title
        level={4}
        style={{
          color: 'black',
          marginBottom: '25px',
          textTransform: 'uppercase',
          letterSpacing: '5px',
        }}
      >
        Sur quel site souhaitez-vous commander ?
      </Title>

      <Row gutter={30} justify="center" style={{textAlign: 'center'}}>
        {storesData.map(
          ({humanName, type, imageKey, backgroundColor}, index) => (
            <Col span={8} key={index} className="store-card">
              <Link
                disabled={
                  loading[type] ||
                  !session?.user[`id_${type}` as keyof UserList]
                }
                onClick={() => handleStoreClick(type)}
              >
                <div className="background">
                  <img
                    alt={humanName}
                    src={`/assets/2022/embouts-${imageKey}-hover.jpg`}
                    style={{
                      padding: '5px',
                    }}
                  />
                </div>
                <div className="foreground">
                  <img
                    alt={`Logo ${humanName}`}
                    src={`/assets/2022/${imageKey}.svg`}
                  />
                  <div>{humanName}</div>
                </div>
              </Link>
            </Col>
          ),
        )}
      </Row>
    </>
  );
};

export default Stores;
