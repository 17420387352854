import {Button, Card, Col, Row, Typography} from 'antd';
import {FC} from 'react';
import Pitch from './Home/Pitch';
import {useUser} from './Session';

const {Title} = Typography;

const Catalogues: FC = () => {
  const user = useUser();

  return (
    <div
      style={{minWidth: '900px', marginBottom: '30px'}}
      className="technical-documents"
    >
      <Pitch background="Catalogues" foreground="" />

      <Row gutter={30} justify="space-around">
        <Col
          span={12}
          lg={8}
          style={{display: user?.id_accessoire ? '' : 'none'}}
        >
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://drive.google.com/file/d/10PIU2f_hXlT0JQCb-ZKYdNYcQYxYJQkQ/view"
          >
            <Card
              className="document-type-card"
              style={{
                border: 'none',
                minHeight: '100%',
                color: 'white',
                cursor: 'pointer',
              }}
            >
              <div className="background">
                <img
                  alt={'Logo catalogue Interson'}
                  src={'/assets/2022/catalogue-interson.png'}
                />
              </div>
              <div
                className="foreground"
                style={{
                  textAlign: 'left',
                  margin: '0',
                  padding: '10px 0 0 20px',
                  textShadow: '1px 1px 2px black',
                }}
              >
                <Title
                  style={{
                    fontWeight: 'normal',
                    marginBottom: 0,
                    color: 'white',
                  }}
                >
                  Catalogue
                </Title>
                <Title style={{marginTop: 0, color: 'white'}}>
                  Interson By Prodways
                </Title>
                <div>
                  <Button
                    type="primary"
                    style={{backgroundColor: '#1D2D4E', border: '#1D2D4E'}}
                  >
                    Je télécharge
                  </Button>
                </div>
              </div>
            </Card>
          </a>
        </Col>
        <Col span={12} lg={8}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://drive.google.com/file/d/1tg_pZ3Xs94XvjVnfKVBnGUMRv-I0mwN0/view?usp=sharing"
          >
            <Card
              className="document-type-card"
              style={{
                border: 'none',
                minHeight: '100%',
                color: 'white',
                cursor: 'pointer',
              }}
            >
              <div className="background">
                <img
                  alt={'Logo catalogue embouts'}
                  src={'/assets/2022/catalogue-embouts.png'}
                />
              </div>
              <div
                className="foreground"
                style={{
                  textAlign: 'left',
                  margin: '0',
                  padding: '10px 0 0 20px',
                  textShadow: '1px 1px 2px black',
                }}
              >
                <Title
                  style={{
                    fontWeight: 'normal',
                    marginBottom: 0,
                    color: 'white',
                  }}
                >
                  Catalogue
                </Title>
                <Title style={{marginTop: 0, color: 'white'}}>
                  Embouts de prothèse
                </Title>
                <div>
                  <Button
                    type="primary"
                    style={{backgroundColor: '#1D2D4E', border: '#1D2D4E'}}
                  >
                    Je télécharge
                  </Button>
                </div>
              </div>
            </Card>
          </a>
        </Col>
      </Row>
    </div>
  );
};

export default Catalogues;
