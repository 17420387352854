import {
  FacebookOutlined,
  InstagramOutlined,
  LinkedinOutlined,
  UserOutlined,
  YoutubeOutlined,
} from '@ant-design/icons';
import {Button, Col, Dropdown, Layout, Menu, Row, Space} from 'antd';
import {FC, useContext, useState} from 'react';
import {Link} from 'react-router-dom';
import {useLocalStorage} from '../hooks/useLocalStorage';
import {openLoginUrl} from '../util';
import Banner from './Home/Banner';
import {SessionContext} from './Session';

const {Header: AntHeader} = Layout;

const Header: FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [session, setSession] = useContext(SessionContext);
  const [, setAgreed] = useLocalStorage('agreedTermsTech');
  const [loading, setLoading] = useState(false);

  const name = session?.user?.raisonSociale;

  /* if (!session?.token && window.location.pathname !== '/login') {
    // @ts-ignore
    window.location = '/login';
    return null;
  } */

  const handleStoreClick = async () => {
    if (!session || loading) {
      return;
    }

    setLoading(true);
    await openLoginUrl('accessoire', session);
    setLoading(false);
  };

  const handleLogout = () => {
    setSession(undefined);
    setAgreed(undefined);
  };

  const menu = (
    <Menu>
      <Menu.Item key="0" disabled={true} style={{textAlign: 'center'}}>
        {name}
      </Menu.Item>

      <Menu.Item key="1">
        <Link to="/stores">
          <Button type="link">Commande Embouts</Button>
        </Link>
      </Menu.Item>
      <Menu.Item key="2">
        <Link to="" onClick={handleStoreClick}>
          <Button type="link" loading={loading}>
            Commande Accessoires
          </Button>
        </Link>
      </Menu.Item>
      <Menu.Item key="3">
        <Link to="/orders">
          <Button type="link">Suivi de commande</Button>
        </Link>
      </Menu.Item>
      <Menu.Item key="4">
        <Link to="/request/order">
          <Button type="link">Bon de commande</Button>
        </Link>
      </Menu.Item>
      <Menu.Item key="5">
        <Link to="/request/delivery">
          <Button type="link">Bon de livraison</Button>
        </Link>
      </Menu.Item>
      <Menu.Item key="6">
        <Link to="/request/invoice">
          <Button type="link">Facture</Button>
        </Link>
      </Menu.Item>
      <Menu.Item key="7">
        <Link to="/settings">
          <Button type="link">Paramètres</Button>
        </Link>
      </Menu.Item>

      <Menu.Item key="8">
        <Link to="/login" onClick={handleLogout}>
          <Button type="link">Déconnexion</Button>
        </Link>
      </Menu.Item>
    </Menu>
  );

  return (
    <AntHeader
      style={{
        backgroundColor: 'white',
        padding: '25px 60px 25px 60px',
        position: 'relative',
        height: '100%',
      }}
    >
      <Row gutter={15}>
        <Col span={3} style={{maxWidth: '256px'}}>
          <Link to="/">
            <img
              src="/logo/EAROW.png"
              alt="Logo EAROW by Prodways"
              style={{
                maxWidth: '100%',
                position: 'relative',
                top: '-14px',
              }}
            />
          </Link>
        </Col>
        <Col span={11} lg={14}>
          <Space direction="vertical" align="center">
            <p
              style={{
                position: 'relative',
                top: '-14px',
                fontSize: '15px',
                lineHeight: '20px',
                color: '#28314e',
                marginLeft: '20px',
              }}
            >
              <strong style={{textTransform: 'uppercase'}}>
                Commande, gestion et suivi de commande...
              </strong>
              <br />
              La seule plateforme dédiée aux audioprothésistes où tout est à
              portée de clic !
            </p>
          </Space>
        </Col>

        <Col span={4} className="header-social-container">
          <a
            href="//www.facebook.com/intersonbyprodways/"
            rel="noreferrer noopener"
            target="_blank"
          >
            <Button
              className="facebook"
              shape="circle"
              icon={<FacebookOutlined />}
            />
          </a>
          <a
            href="//www.instagram.com/intersonbyprodways/"
            rel="noreferrer noopener"
            target="_blank"
          >
            <Button
              className="instagram"
              shape="circle"
              icon={<InstagramOutlined />}
            />
          </a>
          <a
            href="//www.youtube.com/channel/UC4IJAMz5Rb3CSHuNq8vR5MQ"
            rel="noreferrer noopener"
            target="_blank"
          >
            <Button
              className="youtube"
              shape="circle"
              icon={<YoutubeOutlined />}
            />
          </a>
          <a
            href="//www.linkedin.com/company/interson-by-prodways/?viewAsMember=true"
            rel="noreferrer noopener"
            target="_blank"
          >
            <Button
              className="linkedin"
              shape="circle"
              icon={<LinkedinOutlined />}
            />
          </a>
        </Col>

        {session?.token ? (
          <>
            <Col span={6} lg={3} className="user-col">
              <Dropdown overlay={menu} placement="bottomCenter">
                <Button type="ghost" style={{border: 'none'}}>
                  <UserOutlined style={{fontSize: '30px', color: '#28314e'}} />
                </Button>
              </Dropdown>
            </Col>
          </>
        ) : null}

        <Col span={24}>
          <div
            style={{
              position: 'relative',
              top: '-10px',
              maxWidth: '100%',
              width: '1200px',
              margin: 'auto',
              borderBottom: 'solid gray 1px',
            }}
          />
        </Col>

        <Col span={24}>
          <Banner />
        </Col>
      </Row>
    </AntHeader>
  );
};

export default Header;
